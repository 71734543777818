<template>
  <div>
    <v-row justify="center" class="mt-11 mx-0">
      <!-- DESKTOP -->
      <v-col cols="8" class="text-left mx-0 mb-xl-margin-update-pro">
        <!-- <p class="txt-upload-commision">Carga archivo de provisiones</p> -->
        <h1 class="upload-provision-margin__title">Provisiones de comisión</h1>
      </v-col>
      <!-- MOBILE -->
      <v-col
        style="text-align: left"
        cols="12"
        class="py-4 px-0 index-f-sm mb-sm-margin-update-pro"
      >
        <div class="d-flex">
          <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
            <img width="15" src="../../assets/icon/navigate_before.png" />
          </v-btn>
          <p class="text-title-1">
            <label
              style="padding-top: 5%"
              class="text-apps-blue-sm txt-capitalize"
            >
              {{ name }},
            </label>
            <label style="padding-top: 5%" class="text-apps-blue-sm">
              acá encontrará
            </label>
          </p>
        </div>
        <p style="text-align: left" class="text-apps-sm mb-1 ml-9">
          para adjuntar documento
        </p>
      </v-col>
    </v-row>
    <v-form ref="form">
      <v-row justify="center" class="mx-0">
        <v-col cols="11" sm="8" md="8" lg="8" xl="8">
          <v-autocomplete
            v-model="form.costCenter"
            :items="listCostCenter"
            label="Seleccione el centro de costos"
            item-text="name"
            item-value="name"
          ></v-autocomplete>
        </v-col>
        <v-col
          v-if="!form.file"
          cols="11"
          sm="8"
          md="8"
          lg="8"
          xl="8"
          class="text-left"
        >
          <div class="flex md12">
            <div id="app2" v-cloak @drop.prevent="addFile" @dragover.prevent>
              <input
                ref="excel-upload-input"
                class="excel-upload-input"
                type="file"
                @change="handleClick"
                accept=".xlsx, .xlx, .xls"
              />
              <center class="mt-15 pt-5">
                <img src="../../assets/icon/upload.svg" />
              </center>
              <center class="mt-10">
                <p class="txt-subtitle-commisions pb-0 mb-0">
                  Adjunte un archivo
                  <v-btn
                    @click="handleUpload"
                    class="pl-1 ml-1"
                    color="#466BE3"
                    text
                    ><u>Busque en su dispositivo</u></v-btn
                  >
                </p>
                <p class="txt-commisions-ligth">
                  Debe ser un archivo .XLSX o .XLX, .XLS
                </p>
              </center>
            </div>
          </div>
        </v-col>
        <!-- <v-col
          cols="11"
          sm="8"
          md="8"
          lg="8"
          xl="8"
          class="mt-0 pt-0 pb-8 mx-0 px-0"
          v-else
        >
          <v-card class="card-file-upload" elevation="0">
            <v-row>
              <v-col cols="1">
                <img
                  width="53"
                  class="mt-3 ml-2"
                  src="../../assets/icon/upload.svg"
                />
              </v-col>
              <v-col cols="10" class="text-left">
                <p class="mb-1 mt-3 txt-info-file-title">
                  {{ infoFile.name }}
                </p>
                <p class="mb-2 txt-info-file-subtitle">
                  {{ infoFile.size }} MB
                </p>
                <v-progress-linear
                  class="mb-3"
                  color="#466be3"
                  :height="5"
                  :indeterminate="loadFile"
                  stream
                  value="100"
                ></v-progress-linear>
              </v-col>
              <v-col cols="1">
                <v-btn @click="form.file = null" class="mt-4" fab icon small>
                  <v-icon color="#626262"> mdi-close-circle-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->
        <v-col v-else cols="11" sm="8" md="8" lg="8" xl="8" class="mx-0 px-0">
          <v-card class="card-file-upload" elevation="0">
            <v-row class="mx-0 px-0">
              <v-col class="mx-0 px-0" cols="10" md="1" lg="1" xl="1">
                <v-layout justify-center>
                  <img
                    width="53"
                    class="mt-3 ml-2"
                    src="../../assets/icon/upload.svg"
                  />
                </v-layout>
              </v-col>
              <v-col
                cols="10"
                class="text-left mt-0 pa-0 pt-5 update-provision__title-file-desktop"
                md="10"
                lg="10"
                xl="10"
              >
                <p class="mb-1 mt-0 txt-info-file-title">
                  {{ infoFile.name }}
                </p>
                <p class="mb-2 txt-info-file-subtitle">
                  {{ infoFile.size }} MB
                </p>
                <v-progress-linear
                  class="mb-4"
                  color="#466be3"
                  :height="5"
                  :indeterminate="loadFile"
                  stream
                  value="100"
                ></v-progress-linear>
              </v-col>
              <v-col class="mx-0 px-0" cols="2" md="1" lg="1" xl="1">
                <v-btn @click="form.file = null" class="mt-1" fab icon small>
                  <v-icon color="#626262"> mdi-close-circle-outline </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                class="text-left mt-0 pt-0 update-provision__title-file-mobile"
              >
                <p class="mb-1 mt-0 txt-info-file-title">
                  {{ infoFile.name }}
                </p>
                <p class="mb-2 txt-info-file-subtitle">
                  {{ infoFile.size }} MB
                </p>
                <v-progress-linear
                  class="mb-4"
                  color="#466be3"
                  :height="5"
                  :indeterminate="loadFile"
                  stream
                  value="100"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center" class="mx-0">
      <v-col cols="5" sm="4" md="4" lg="4" xl="4" class="mx-0">
        <v-btn
          @click="$router.go(-1)"
          style="background-color: #fff"
          block
          rounded
          outlined
          color="#466BE3"
        >
          atras
        </v-btn>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4" class="mx-0">
        <v-btn
          :disabled="!form.file"
          @click="saveDocument()"
          block
          rounded
          color="#466BE3"
          dark
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>

    <!-- <v-dialog v-model="message.dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 request-project">
          <v-layout justify-center>
            {{ message.title }}
          </v-layout>
        </v-card-title>
        <v-card-text class="request-txt-project">
          {{ message.sms }}
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loadingAll: false,
      loadFile: true,
      infoFile: {
        name: "",
        size: "",
      },
      form: {
        file: null,
        costCenter: null,
      },
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      listCostCenter: [],
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      var extension = droppedFiles[0].type.split("/")[1].toLowerCase();
      if (
        extension != "xlsx" &&
        extension != "xls" &&
        extension != "csv" &&
        extension != "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.message.dialog = true;
        this.message.sms =
          "La extensión del archivo es inválido, debe ser un XLSX***";
        this.message.title = "¡Oh noo!";
        this.message.type = "error";
      } else {
        const rawFile = droppedFiles[0];
        if (!rawFile) return;
        this.form.file = rawFile;
        this.infoFile.name = rawFile.name;
        this.infoFile.size = (rawFile.size / 1024 / 1024).toFixed(2);
        this.loadFile = false;
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.loadFile = true;
      const files = e.target.files;
      const extension = files[0].name.split(".")[1].toLowerCase();
      console.log(extension);
      if (
        extension != "xlsx" &&
        extension != "xls" &&
        extension != "csv" &&
        extension != "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.message.dialog = true;
        this.message.sms =
          "La extensión del archivo es inválido, debe ser un XLSX, CSV, XLX";
        this.message.title = "¡Oh noo!";
        this.message.type = "error";
      } else {
        const rawFile = files[0]; // only use files[0]
        if (!rawFile) return;
        this.form.file = rawFile;
        this.infoFile.name = rawFile.name;
        this.infoFile.size = (rawFile.size / 1024 / 1024).toFixed(2);
        this.loadFile = false;
      }
    },
    getCostCenter() {
      Api.MarginCommisions()
        .getCostCenter(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listCostCenter = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDocument() {
      if (this.$refs.form.validate()) {
        this.loadingAll = true;
        const formData = new FormData();
        formData.append("file", this.form.file);
        formData.append("costCenter", this.form.costCenter);

        Api.MarginCommisions()
          .uploadFileProvisional1(formData, this.token)
          .then((res) => {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            if (res.data.cod == 0) {
              this.message.title = "Excelente";
              this.message.type = "success";
              this.message.redirect = "marginCommisions";
            } else {
              this.message.title = "¡Oh noo!";
              this.message.type = "error";
              this.message.redirect = "marginCommisions";
            }

            // setTimeout(() => {
            //   // this.message.dialog = false;
            //   this.$router.push({ name: "marginCommisions" });
            // }, 5000);

            // if (res.data.cod == 0) {
            //   this.message.title = "Muy bien";
            // } else {
            //   this.message.title = "Oops.. hay un error";
            // }
            // this.message.sms = res.data.message;
          })
          .catch((error) => {
            this.message.dialog = true;
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
            this.message.redirect = "marginCommisions";
            // console.log(error);
            // this.message.dialog = true;
            // setTimeout(() => {
            //   this.message.dialog = false;
            // }, 3000);
            // this.message.title = "Oops.. hay un error";
            // this.message.sms = error.response.data.message;
          })
          .finally(() => {
            this.loadingAll = false;
          });
      }
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.getCostCenter();
  },
};
</script>
<style lang="less" scoped>
@import "./../../assets/css/main.less";
#app2 {
  background: #ffffff;
  border: 1px dashed #466be3;
  border-radius: 15px;
  height: 353px;
}

.txt-upload-commision {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  color: #353535;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.card-file-upload {
  background: #ffffff !important;
  border: 1px solid #466be3 !important;
  border-radius: 10px !important;
}
.txt-info-file-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #626262;
}

.txt-info-file-subtitle {
  font-family: "RobotoMedium";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #626262;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #c6c6c6 !important;
  color: #ffffff !important;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}
.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.mb-xl-margin-update-pro {
  display: block;
}

.mb-sm-margin-update-pro {
  display: none;
}

.update-provision__title-file-mobile {
  display: none;
}

.upload-provision-margin__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

/* TABLET */

@media (max-width: 1023px) {
}

@media (max-width: 960px) {
  .update-provision__title-file-desktop {
    display: none;
  }

  .update-provision__title-file-mobile {
    display: block;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-margin-update-pro {
    display: none;
  }
  .mb-sm-margin-update-pro {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-margin-update-pro {
    display: none;
  }
  .mb-sm-margin-update-pro {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-margin-update-pro {
    display: none;
  }
  .mb-sm-margin-update-pro {
    display: block;
    text-align: initial;
  }
}
</style>
